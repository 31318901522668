import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, getDoc, deleteDoc, updateDoc, addDoc, setDoc } from 'firebase/firestore';
import { useAuth } from '../hooks/useAuth';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';
import { CSVLink } from 'react-csv';
import './AdminDashboard.css';

Modal.setAppElement('#root');

const AdminDashboard = () => {
  const [eventName, setEventName] = useState(''); // For event name input
const [eventDate, setEventDate] = useState(''); // For event date input

  const { currentUser } = useAuth();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'time', direction: 'descending' });
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const [selectedDate, setSelectedDate] = useState(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  });
  const [editMode, setEditMode] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [newAction, setNewAction] = useState('');
  const [manualDateTime, setManualDateTime] = useState('');

  // For daily updates
  const [dua, setDua] = useState('');
  const [hadith, setHadith] = useState('');
  const [notes, setNotes] = useState('');
  const [hadithOfTheDay, ] = useState(null);

  // States for Export Data modal
  const [isExportModalOpen, setExportModalOpen] = useState(false);
  const [fromDate, setFromDate] = useState(selectedDate);
  const [toDate, setToDate] = useState(selectedDate);
  const [exportData, setExportData] = useState([]);

  useEffect(() => {
    const fetchRecords = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists() || userDoc.data().role !== 'admin') {
          throw new Error('permission-denied');
        }

        const recordsQuerySnapshot = await getDocs(collection(db, 'records'));
        const recordsData = recordsQuerySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        const filteredRecords = recordsData.filter(record => {
          const recordDate = new Date(record.time).toLocaleDateString('en-CA'); // en-CA format is YYYY-MM-DD
          return recordDate === selectedDate;
        });

        const studentMap = {};

        filteredRecords.forEach(record => {
          if (!studentMap[record.studentName]) {
            studentMap[record.studentName] = {
              id: record.id,
              studentName: record.studentName,
              dropOffParent: null,
              pickUpParent: null,
              dropOffTime: null,
              pickUpTime: null,
              status: 'Pending',
            };
          }

          if (record.action === 'Drop-off') {
            studentMap[record.studentName].dropOffTime = record.time;
            studentMap[record.studentName].dropOffParent = record.parentName;  // Store drop-off parent
          } else if (record.action === 'Pick-up') {
            studentMap[record.studentName].pickUpTime = record.time;
            studentMap[record.studentName].pickUpParent = record.parentName;  // Store pick-up parent
          }

          studentMap[record.studentName].status =
            studentMap[record.studentName].dropOffTime && studentMap[record.studentName].pickUpTime
              ? 'Picked up'
              : 'Pending';
        });

        const aggregatedRecords = Object.values(studentMap).map(student => ({
          ...student,
          latestActionTime: student.pickUpTime || student.dropOffTime,
        }));

        setRecords(aggregatedRecords);
      } catch (error) {
        if (error.message === 'permission-denied') {
          toast.error('You do not have the necessary permissions to view this data.');
        } else if (error.message === 'Network request failed') {
          toast.error('Network error: Please check your connection.');
        } else {
          toast.error('An unexpected error occurred. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    };

    const fetchDailyUpdates = async () => {
      try {
        const dailyUpdatesRef = doc(db, 'dailyUpdates', 'current');
        const dailyDoc = await getDoc(dailyUpdatesRef);

        if (dailyDoc.exists()) {
          const data = dailyDoc.data();
          setDua(data.dua);
          setHadith(data.hadith);
          setNotes(data.notes);
        }
      } catch (error) {
        if (error.message === 'Network request failed') {
          toast.error('Failed to load daily updates: Please check your network connection.');
        } else {
          toast.error('Error loading daily updates.');
        }
      }
    };

    fetchRecords();
    fetchDailyUpdates();
  }, [currentUser, selectedDate]);

  const filteredRecords = records.filter(record =>
    record.studentName?.toLowerCase().includes(search.toLowerCase()) ||
    record.parentName?.toLowerCase().includes(search.toLowerCase())
  );

  const sortedRecords = filteredRecords.sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = sortedRecords.slice(indexOfFirstRecord, indexOfLastRecord);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleDelete = async (studentName, action) => {
    try {
      const recordsQuerySnapshot = await getDocs(collection(db, 'records'));
      const recordsData = recordsQuerySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      const recordToDelete = recordsData.find(record => 
        record.studentName === studentName && record.action === action
      );

      if (recordToDelete) {
        await deleteDoc(doc(db, 'records', recordToDelete.id));
        setRecords(records.filter(record => record.id !== recordToDelete.id));
        toast.success('Record deleted successfully');
      } else {
        toast.error('Record not found');
      }
    } catch (err) {
      toast.error('Error deleting record: ' + err.message);
    }
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setNewAction(record.action);
    setManualDateTime(record.time ? new Date(record.time).toISOString().slice(0, 16) : '');
    setEditMode(true);
  };

  const handleUpdate = async () => {
    try {
      const recordRef = doc(db, 'records', editRecord.id);
      const updatedFields = {
        studentName: editRecord.studentName,
        parentName: editRecord.parentName,
        action: newAction,
        time: new Date(manualDateTime).toISOString()
      };

      if (newAction === 'Drop-off') {
        updatedFields.dropOffTime = new Date(manualDateTime).toISOString();
      } else if (newAction === 'Pick-up') {
        updatedFields.pickUpTime = new Date(manualDateTime).toISOString();
      }

      await updateDoc(recordRef, updatedFields);

      setRecords(records.map(record =>
        record.id === editRecord.id ? { ...record, ...updatedFields } : record
      ));
      setEditMode(false);
      toast.success('Record updated successfully');
    } catch (err) {
      toast.error('Error updating record: ' + err.message);
    }
  };

  const handleAddLog = async () => {
    try {
      const newLog = {
        studentName: editRecord.studentName,
        parentName: editRecord.parentName,
        action: newAction,
        time: new Date(manualDateTime).toISOString()
      };

      if (newAction === 'Drop-off') {
        newLog.dropOffTime = new Date(manualDateTime).toISOString();
      } else if (newAction === 'Pick-up') {
        newLog.pickUpTime = new Date(manualDateTime).toISOString();
      }

      await addDoc(collection(db, 'records'), newLog);

      setRecords([...records, newLog]);
      setEditMode(false);
      toast.success('Log added successfully');
    } catch (err) {
      toast.error('Error adding log: ' + err.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditRecord({ ...editRecord, [name]: value });
  };

  const handleDateTimeChange = (e) => {
    setManualDateTime(e.target.value);
  };

  const handleDailyUpdateSave = async () => {
    try {
      const dailyUpdatesRef = doc(db, 'dailyUpdates', 'current');
      await setDoc(dailyUpdatesRef, {
        dua,
        hadith,
        notes
      });
      toast.success('Daily updates saved successfully');
    } catch (err) {
      toast.error('Error saving daily updates: ' + err.message);
    }
  };

  // Export data related methods
  const openExportModal = () => {
    setExportModalOpen(true);
  };

  const closeExportModal = () => {
    setExportModalOpen(false);
  };

  const handleExport = async () => {
    if (!fromDate || !toDate) {
      toast.error("Please select both 'From' and 'To' dates.");
      return;
    }
  
    setLoading(true);
    try {
      const recordsQuerySnapshot = await getDocs(collection(db, 'records'));
      const recordsData = recordsQuerySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
  
      // Filter records within the date range
      const filteredExportData = recordsData.filter(record => {
        const recordDate = new Date(record.time);
        const from = new Date(fromDate);
        const to = new Date(toDate);
        return recordDate >= from && recordDate <= to;
      });
  
      // Create a map to hold student data
      const studentMap = {};
  
      filteredExportData.forEach(record => {
        const date = new Date(record.time).toLocaleDateString('en-CA'); // YYYY-MM-DD format
        if (!studentMap[record.studentName]) {
          studentMap[record.studentName] = {
            date: date,
            dropOffParent: null,
            dropOffTime: null,
            pickUpParent: null,
            pickUpTime: null,
          };
        }
  
        if (record.action === 'Drop-off') {
          studentMap[record.studentName].dropOffParent = record.parentName;
          studentMap[record.studentName].dropOffTime = new Date(record.time).toLocaleTimeString(); // Format the time
        } else if (record.action === 'Pick-up') {
          studentMap[record.studentName].pickUpParent = record.parentName;
          studentMap[record.studentName].pickUpTime = new Date(record.time).toLocaleTimeString(); // Format the time
        }
      });
  
      // Prepare the export data
      const exportData = Object.values(studentMap).map(student => ({
        Date: student.date,
        'Drop-off Parent': student.dropOffParent || 'N/A',
        'Drop-off Time': student.dropOffTime || 'N/A',
        'Pick-up Parent': student.pickUpParent || 'N/A',
        'Pick-up Time': student.pickUpTime || 'N/A',
      }));
  
      // Sort exportData by date
      const sortedExportData = exportData.sort((a, b) => new Date(a.Date) - new Date(b.Date));
  
      setExportData(sortedExportData); // Set the sorted export data for the CSVLink
      toast.success('Data generated for export.');
    } catch (error) {
      toast.error('Failed to generate export data.');
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <div className="container">
      <ToastContainer />
      <h1>Student Records</h1>
      <div className="filter-container">
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          className="date-picker"
        />
        <input
          type="text"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="search-input"
        />
      </div>
      {loading ? (
        <ClipLoader />
      ) : (
        <div className="table-container">
          <table className="records-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('studentName')}>Student Name</th>
                <th>Drop-off Parent</th>
                <th>Pick-up Parent</th>
                <th onClick={() => handleSort('dropOffTime')}>Drop-off Time</th>
                <th onClick={() => handleSort('pickUpTime')}>Pick-up Time</th>
                <th onClick={() => handleSort('status')}>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((record) => (
                <tr key={record.id}>
                  <td>{record.studentName}</td>
                  <td>{record.dropOffParent}</td>
                  <td>{record.pickUpParent}</td>
                  <td>
                    {record.dropOffTime ? new Date(record.dropOffTime).toLocaleString() : 'N/A'}
                    <button className="delete-btn" onClick={() => handleDelete(record.studentName, 'Drop-off')}>
                      <i className="fas fa-times" aria-label="Delete Drop-off Time" title="Delete Drop-off Time"></i>
                    </button>
                  </td>
                  <td>
                    {record.pickUpTime ? new Date(record.pickUpTime).toLocaleString() : 'N/A'}
                    <button className="delete-btn" onClick={() => handleDelete(record.studentName, 'Pick-up')}>
                      <i className="fas fa-times" aria-label="Delete Pick-up Time" title="Delete Pick-up Time"></i>
                    </button>
                  </td>
                  <td className={`status-${record.status?.toLowerCase().replace(' ', '-')}`}>
                    <span className={`badge badge-${record.status?.toLowerCase().replace(' ', '-')}`}>
                      {record.status}
                    </span>
                  </td>
                  <td>
                    <button onClick={() => handleEdit(record)} className="btn btn-edit">Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from({ length: Math.ceil(sortedRecords.length / recordsPerPage) }, (_, i) => (
              <button key={i} onClick={() => handlePageChange(i + 1)} className={currentPage === i + 1 ? 'active' : ''}>
                {i + 1}
              </button>
            ))}
          </div>
        </div>
      )}
      <button onClick={openExportModal} className="btn btn-primary">
        Export Data
      </button>
      <Modal
        isOpen={isExportModalOpen}
        onRequestClose={closeExportModal}
        contentLabel="Export Data"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Export Data</h2>
        <form>
          <div>
            <label>From Date:</label>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              className="input-field"
            />
          </div>
          <div>
            <label>To Date:</label>
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              className="input-field"
            />
          </div>
          <CSVLink data={exportData} filename={`student_records_${fromDate}_to_${toDate}.csv`} className="btn btn-export">
            Download CSV
          </CSVLink>
          <button type="button" onClick={handleExport} className="btn btn-export">Generate Data</button>
        </form>
      </Modal>
      <Modal
        isOpen={editMode}
        onRequestClose={() => setEditMode(false)}
        contentLabel="Edit Record"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Edit Record</h2>
        <form>
          <div>
            <label>Student Name:</label>
            <input
              type="text"
              name="studentName"
              value={editRecord?.studentName || ''}
              onChange={handleChange}
              required
              className="input-field"
            />
          </div>
          <div>
            <label>Parent Name:</label>
            <input
              type="text"
              name="parentName"
              value={editRecord?.parentName || ''}
              onChange={handleChange}
              required
              className="input-field"
            />
          </div>
          <div>
            <label>Action:</label>
            <select value={newAction} onChange={(e) => setNewAction(e.target.value)} className="input-field">
              <option value="">Select Action</option>
              <option value="Drop-off">Drop-off</option>
              <option value="Pick-up">Pick-up</option>
            </select>
          </div>
          <div>
            <label>Date and Time:</label>
            <input
              type="datetime-local"
              value={manualDateTime}
              onChange={handleDateTimeChange}
              required
              className="input-field"
            />
          </div>
          <button type="button" onClick={handleAddLog} className="btn btn-add-log">Add Log</button>
        </form>
      </Modal>
      <div className="daily-updates">
        <h2>Daily Updates</h2>
        <form>
          <div>
            <label>Dua of the Day:</label>
            <textarea value={dua} onChange={(e) => setDua(e.target.value)} className="input-field" placeholder="Enter Dua of the Day" />
          </div>
          <div>
            <label>Hadith of the Day:</label>
            {hadithOfTheDay && (
              <div className="hadith-container">
                <p><strong>Arabic:</strong> {hadithOfTheDay.arabic}</p>
                <p><strong>English:</strong> {hadithOfTheDay.english.text}</p>
                <p><strong>Narrated by:</strong> {hadithOfTheDay.english.narrator}</p>
              </div>
            )}
            <textarea value={hadith} onChange={(e) => setHadith(e.target.value)} className="input-field" placeholder="Enter Hadith of the Day" />
          </div>
          <div>
            <label>Notes:</label>
            <textarea value={notes} onChange={(e) => setNotes(e.target.value)} className="input-field" placeholder="Enter Notes" />
          </div>
          <button type="button" onClick={handleDailyUpdateSave} className="btn btn-save-updates">Save Updates</button>
        </form>
      </div>
    </div>
  );
};

export default AdminDashboard;
