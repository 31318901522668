import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; // Import Navigate
import LogVisit from './components/LogVisit';
import Login from './components/Login';
import SignUp from './components/SignUp';
import ResetPassword from './components/ResetPassword';
import Profile from './components/Profile'; // Corrected component name
import ActivityLogs from './components/ActivityLogs';
import Analytics from './components/Analytics';
import QrCodeGenerator from './components/QrCodeGenerator';
import EventCalendar from './components/EventCalendar'; // Admin Calendar
import CalendarView from './components/CalendarView'; // Public Calendar View for Parents
import { AuthProvider, useAuth } from './hooks/useAuth';
import './App.css';  // Make sure this path is correct
import { db } from './firebase'; // Import Firestore instance
import Header from './components/Header'; // Import the Header component

const PrivateRoute = ({ element }) => {
  const { currentUser } = useAuth();
  return currentUser ? element : <Navigate to="/login" />;
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <div className="app-container">
          <Header />
          <main>
            <Routes>
              <Route path="/log-visit" element={<LogVisit db={db} />} /> {/* Public route */}
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
              <Route path="/activity-logs" element={<PrivateRoute element={<ActivityLogs />} />} />
              <Route path="/analytics" element={<PrivateRoute element={<Analytics />} />} />
              <Route path="/qr-test" element={<PrivateRoute element={<QrCodeGenerator />} />} />
              <Route path="/calendar" element={<PrivateRoute element={<EventCalendar />} />} /> {/* Admin Calendar */}
              <Route path="/calendar-view" element={<CalendarView />} /> {/* Public View for Parents */}
            </Routes>
          </main>
          <Footer />
        </div>
      </AuthProvider>
    </Router>
  );
};

const Footer = () => (
  <footer className="footer">
    <p className="footer-text">&copy; 2024 Little Angels. All rights reserved.</p>
  </footer>
);

export default App;
