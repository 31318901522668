import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { getFunctions, httpsCallable } from 'firebase/functions';

const QrCodeGenerator = () => {
  const [token, setToken] = useState('');

  useEffect(() => {
    const fetchPersistentToken = async () => {
      const functions = getFunctions();
      const getPersistentToken = httpsCallable(functions, 'getPersistentToken');
      const result = await getPersistentToken();
      if (result.data.token) {
        setToken(result.data.token);
      } else {
        // Generate a new token if not found (initial setup scenario)
        const createPersistentToken = httpsCallable(functions, 'createPersistentToken');
        const newResult = await createPersistentToken();
        setToken(newResult.data.token);
      }
    };

    fetchPersistentToken();
  }, []);

  const url = `https://littleangelsprek.com/log-visit?token=${token}`;

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h2>Scan this QR code to test the Log Visit page</h2>
      {token && (
        <>
          <QRCode value={url} />
          <p>{url}</p>
        </>
      )}
    </div>
  );
};

export default QrCodeGenerator;
