import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { httpsCallable } from 'firebase/functions';
import 'react-toastify/dist/ReactToastify.css';
import { functions } from '../firebase';
import './Admin.css'; // Add this import for CSS

const Admin = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleAddUser = async (e) => {
    e.preventDefault();
    const createUser = httpsCallable(functions, 'createUser');

    try {
      const result = await createUser({ email, password, name });
      if (result.data.success) {
        setMessage('User created successfully!');
        toast.success('User created successfully!');
        // Reset the form fields
        setEmail('');
        setName('');
        setPassword('');
      } else {
        throw new Error(result.data.error);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="admin-container">
      <ToastContainer />
      <h1>Admin</h1>
      <h3 className="admin-warning">
        This page is for admins to add users, for example more teachers.
      </h3>
      <form onSubmit={handleAddUser} className="admin-form">
        <div className="form-group">
          <label htmlFor="email">Person's Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Person's Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn">Add User</button>
      </form>
      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default Admin;
