import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { db } from '../firebase';
import { collection, addDoc, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import './LogVisit.css';

const LogVisit = () => {
  const [studentName, setStudentName] = useState('');
  const [parentName, setParentName] = useState('');
  const [action, setAction] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [allNames, setAllNames] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [manualDateTime, setManualDateTime] = useState('');
  const [dua, setDua] = useState('');
  const [hadith, setHadith] = useState('');
  const [notes, setNotes] = useState('');

  useEffect(() => {
    const fetchAllNames = async () => {
      try {
        const recordsSnapshot = await getDocs(collection(db, 'records'));
        const names = recordsSnapshot.docs.map(doc => doc.data().studentName);
        setAllNames(Array.from(new Set(names)));
      } catch (error) {
        console.error('Error fetching names:', error);
      }
    };

    const fetchDailyUpdates = async () => {
      try {
        const dailyUpdatesRef = doc(db, 'dailyUpdates', 'current');
        const dailyUpdatesDoc = await getDoc(dailyUpdatesRef);
        if (dailyUpdatesDoc.exists()) {
          const data = dailyUpdatesDoc.data();
          setDua(data.dua || '');
          setHadith(data.hadith || '');
          setNotes(data.notes || '');
        }
      } catch (error) {
        toast.error('Error fetching daily updates: ' + error.message);
      }
    };

    fetchAllNames();
    fetchDailyUpdates();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const nameRegex = /^[a-zA-Z\s]+$/;
    if (!studentName.trim() || !parentName.trim()) {
      setError('Student and Parent names are required.');
      return;
    } else if (!nameRegex.test(studentName) || !nameRegex.test(parentName)) {
      setError('Names can only contain letters and spaces.');
      return;
    } else if (!action) {
      setError('Please select either Drop-off or Pick-up.');
      return;
    }

    setLoading(true);

    try {
      const formattedStudentName = studentName.trim();
      const formattedParentName = parentName.trim();

      if (action === 'Pick-up') {
        const recordsRef = collection(db, 'records');
        const q = query(recordsRef, where('studentName', '==', formattedStudentName), where('action', '==', 'Drop-off'));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          setError(`No corresponding drop-off record found for ${formattedStudentName}.`);
          setLoading(false);
          return;
        }
      }

      const formattedDateTime = manualDateTime || new Date().toISOString();

      const recordsRef = collection(db, 'records');
      const duplicateCheckQuery = query(
        recordsRef,
        where('studentName', '==', formattedStudentName),
        where('parentName', '==', formattedParentName),
        where('action', '==', action),
        where('time', '==', formattedDateTime)
      );
      const duplicateSnapshot = await getDocs(duplicateCheckQuery);

      if (!duplicateSnapshot.empty) {
        setError('Duplicate log detected. This entry has already been recorded.');
        setLoading(false);
        return;
      }

      await addDoc(recordsRef, {
        studentName: formattedStudentName,
        parentName: formattedParentName,
        action,
        time: formattedDateTime,
      });

      setStudentName('');
      setParentName('');
      setAction('');
      setManualDateTime('');
      toast.success('Visit logged successfully');
    } catch (err) {
      if (err.message.includes('permission-denied')) {
        setError('You do not have the necessary permissions to log a visit. Please contact the administrator.');
      } else if (err.message.includes('Network request failed')) {
        setError('Network error: Please check your connection and try again.');
      } else {
        setError('An error occurred while logging the visit. Please try again.');
      }
      toast.error('Error: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const filteredSuggestions = inputLength === 0 ? [] : allNames.filter(name =>
      name.toLowerCase().slice(0, inputLength) === inputValue
    );

    setSuggestions(filteredSuggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = suggestion => suggestion;

  const renderSuggestion = suggestion => (
    <div>{suggestion}</div>
  );

  return (
    <div className="container">
      <ToastContainer />
      <Link to="/calendar-view">
        <button className="btn btn-primary">View School Calendar</button>
      </Link>
      <h2>Log Visit</h2>

      {/* Add a link to view the calendar */}
      

      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="studentName">Student Name:</label>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
              id: 'studentName',
              value: studentName,
              onChange: (_, { newValue }) => setStudentName(newValue),
              required: true
            }}
          />
        </div>
        <div>
          <label htmlFor="parentName">Parent Name:</label>
          <input
            id="parentName"
            type="text"
            value={parentName}
            onChange={(e) => setParentName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Action:</label>
          <input
            type="radio"
            id="drop-off"
            value="Drop-off"
            checked={action === 'Drop-off'}
            onChange={() => setAction('Drop-off')}
          />
          <label htmlFor="drop-off">Drop-off</label>
          <input
            type="radio"
            id="pick-up"
            value="Pick-up"
            checked={action === 'Pick-up'}
            onChange={() => setAction('Pick-up')}
          />
          <label htmlFor="pick-up">Pick-up</label>
        </div>
        <button type="submit" disabled={loading}>Submit</button>
        {error && <p className="error">{error}</p>}
      </form>

      <div className="reminder">
        <p><strong>Reminder:</strong> For accurate record-keeping, please ensure that you use the exact same name for both Drop-off and Pick-up actions.</p>
      </div>

      <div className="forgot-log-container">
        <p>Forgot to add a log? <button className="forgot-log-button" onClick={() => setIsModalOpen(true)}>Click here</button></p>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Forgot to Add Log"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Forgot to Add Log</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="studentNameModal">Student Name:</label>
            <input
              id="studentNameModal"
              type="text"
              value={studentName}
              onChange={(e) => setStudentName(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="parentNameModal">Parent Name:</label>
            <input
              id="parentNameModal"
              type="text"
              value={parentName}
              onChange={(e) => setParentName(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="manualDateTimeModal">Date and Time:</label>
            <input
              id="manualDateTimeModal"
              type="datetime-local"
              value={manualDateTime}
              onChange={(e) => setManualDateTime(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Action:</label>
            <input
              type="radio"
              id="drop-off-modal"
              value="Drop-off"
              checked={action === 'Drop-off'}
              onChange={() => setAction('Drop-off')}
            />
            <label htmlFor="drop-off-modal">Drop-off</label>
            <input
              type="radio"
              id="pick-up-modal"
              value="Pick-up"
              checked={action === 'Pick-up'}
              onChange={() => setAction('Pick-up')}
            />
            <label htmlFor="pick-up-modal">Pick-up</label>
          </div>
          <button type="submit" disabled={loading}>Submit</button>
        </form>
        <button onClick={() => setIsModalOpen(false)}>Close</button>
      </Modal>

      <div className="daily-updates">
        <h3>Dua of the Day</h3>
        <p>{dua}</p>
        <h3>Hadith of the Day</h3>
        <p>{hadith}</p>
        <h3>Important Notes</h3>
        <p>{notes}</p>
      </div>
    </div>
  );
};

export default LogVisit;
