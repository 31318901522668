import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { getAuth } from 'firebase/auth';
import './Header.css'; // Ensure this CSS file is in your project for styling

const Header = () => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // For mobile hamburger menu toggle

  // Function to handle logout
  const handleLogout = () => {
    const auth = getAuth();
    auth.signOut().then(() => {
      navigate('/login');
      setIsOpen(false); // Close the menu after logout
    });
  };

  // Hide the nav links on the "/log-visit" page
  const hideNav = location.pathname === '/log-visit';

  // Function to toggle hamburger menu for mobile devices
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Function to close the menu
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <header className="header">
      <h1 className="header-title">Little Angels</h1>
      {!hideNav && (
        <>
          <button className="hamburger" onClick={toggleMenu}>
            ☰
          </button>
          <nav className={`header-nav ${isOpen ? 'open' : ''}`}>
            {currentUser && (
              <>
                <Link
                  to="/reset-password"
                  className={`nav-link ${location.pathname === '/reset-password' ? 'nav-link-active' : ''}`}
                  onClick={closeMenu}
                >
                  Reset Password
                </Link>
                <Link
                  to="/profile"
                  className={`nav-link ${location.pathname === '/profile' ? 'nav-link-active' : ''}`}
                  onClick={closeMenu}
                >
                  Admin
                </Link>
                <Link
                  to="/activity-logs"
                  className={`nav-link ${location.pathname === '/activity-logs' ? 'nav-link-active' : ''}`}
                  onClick={closeMenu}
                >
                  Activity Logs
                </Link>
                <Link
                  to="/analytics"
                  className={`nav-link ${location.pathname === '/analytics' ? 'nav-link-active' : ''}`}
                  onClick={closeMenu}
                >
                  Analytics
                </Link>
                <Link
                  to="/qr-test"
                  className={`nav-link ${location.pathname === '/qr-test' ? 'nav-link-active' : ''}`}
                  onClick={closeMenu}
                >
                  Test QR Code
                </Link>
                {/* Calendar Link */}
                <Link
                  to="/calendar" // Add link to the calendar
                  className={`nav-link ${location.pathname === '/calendar' ? 'nav-link-active' : ''}`}
                  onClick={closeMenu}
                >
                  Calendar
                </Link>
                <button onClick={handleLogout} className="logout-button">
                  Logout
                </button>
              </>
            )}
          </nav>
        </>
      )}
    </header>
  );
};

export default Header;
