import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { db } from '../firebase';
import { collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { Tooltip } from '@mantine/core'; // Import Mantine Tooltip
import './EventCalendar.css';

const EventCalendar = () => {
  const [events, setEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newEventName, setNewEventName] = useState('');
  const [newEventDescription, setNewEventDescription] = useState(''); // For event description
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null); // Store selected event for deletion
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsSnapshot = await getDocs(collection(db, 'events'));
        const eventsList = eventsSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id, // Keep track of document ID for deletion
            title: data.name,
            date: data.date,
            description: data.description || 'No description available'
          };
        });
        setEvents(eventsList);
      } catch (err) {
        setError('Error fetching events: ' + err.message);
        toast.error('Error fetching events.');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  // Handle date click to open the modal for adding an event
  const handleDateClick = (arg) => {
    setSelectedDate(arg.dateStr);
    setIsModalOpen(true);
  };

  // Handle event click to open modal for event details
  const handleEventClick = (clickInfo) => {
    const clickedEvent = events.find(event => event.id === clickInfo.event.id);
    setSelectedEvent(clickedEvent); // Store selected event
    setIsModalOpen(true);
  };

  // Handle adding a new event with description
  const handleAddEvent = async () => {
    if (!newEventName) {
      toast.error('Please enter an event name.');
      return;
    }

    try {
      const newDocRef = await addDoc(collection(db, 'events'), {
        name: newEventName,
        date: selectedDate,
        description: newEventDescription, // Add the event description
      });

      // Update the events state with the new event, including the new document ID
      setEvents([...events, { id: newDocRef.id, title: newEventName, date: selectedDate, description: newEventDescription }]);
      setNewEventName('');
      setNewEventDescription(''); // Clear description input
      setIsModalOpen(false);
      toast.success('Event added successfully!');
    } catch (error) {
      toast.error('Error adding event: ' + error.message);
    }
  };

  // Handle event deletion
  const handleDeleteEvent = async () => {
    if (!selectedEvent) return;

    const confirmDelete = window.confirm(`Are you sure you want to delete the event "${selectedEvent.title}"?`);

    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'events', selectedEvent.id)); // Delete from Firestore
        setEvents(events.filter(event => event.id !== selectedEvent.id)); // Remove from state
        setSelectedEvent(null); // Clear selected event
        setIsModalOpen(false); // Close modal
        toast.success('Event deleted successfully!');
      } catch (error) {
        toast.error('Error deleting event: ' + error.message);
      }
    }
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setNewEventName('');
    setNewEventDescription('');
    setSelectedEvent(null); // Clear selected event
    setSelectedDate(null);
  };

  if (loading) {
    return <div>Loading events...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="calendar-container">
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        dateClick={handleDateClick}
        eventClick={handleEventClick} // Event click handler for details and deletion
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay',
        }}
        eventContent={renderEventContent} // Custom event rendering
        eventDisplay="block"
        dayMaxEventRows={3} // Limit number of events shown per day on mobile
        themeSystem="standard"
        showNonCurrentDates={false}
        height="auto"
        fixedWeekCount={false}
        contentHeight="auto"
      />

      {/* Modal for adding or viewing an event */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Event Details"
        className="modal"
        overlayClassName="modal-overlay"
      >
        {selectedEvent ? (
          <div>
            <h2>{selectedEvent.title}</h2>
            <p><strong>Date:</strong> {selectedEvent.date}</p>
            <p><strong>Description:</strong> {selectedEvent.description}</p>
            <button onClick={handleDeleteEvent} className="btn btn-danger">Delete Event</button>
            <button onClick={closeModal} className="btn btn-secondary">Close</button>
          </div>
        ) : (
          <div>
            <h2>Add Event</h2>
            <p>Date: {selectedDate}</p>
            <input
              type="text"
              placeholder="Event Name"
              value={newEventName}
              onChange={(e) => setNewEventName(e.target.value)}
            />
            <textarea
              placeholder="Event Description"
              value={newEventDescription}
              onChange={(e) => setNewEventDescription(e.target.value)}
              rows="4"
            />
            <button onClick={handleAddEvent} className="btn btn-primary">Add Event</button>
            <button onClick={closeModal} className="btn btn-secondary">Cancel</button>
          </div>
        )}
      </Modal>
    </div>
  );
};

// Render event content with description tooltip
const renderEventContent = (eventInfo) => {
  return (
    <Tooltip label={eventInfo.event.extendedProps.description} withArrow>
      <div className="event-content">
        <strong>{eventInfo.timeText}</strong> <i>{eventInfo.event.title}</i>
      </div>
    </Tooltip>
  );
};

export default EventCalendar;
