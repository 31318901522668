import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './Analytics.css';

const Analytics = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const recordsQuerySnapshot = await getDocs(collection(db, 'records'));
      const recordsData = recordsQuerySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      const groupedData = recordsData.reduce((acc, record) => {
        const date = new Date(record.time).toLocaleDateString();
        if (!acc[date]) {
          acc[date] = { date, dropOff: 0, pickUp: 0 };
        }
        if (record.action === 'Drop-off') {
          acc[date].dropOff++;
        } else if (record.action === 'Pick-up') {
          acc[date].pickUp++;
        }
        return acc;
      }, {});

      const chartData = Object.values(groupedData);

      setData(chartData);
    };

    fetchData();
  }, []);

  return (
    <div className="analytics-container">
      <h2>Data Analytics</h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="dropOff" fill="#8884d8" />
          <Bar dataKey="pickUp" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Analytics;
