import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCFr7VqHFXvuUxhOtvi8LKcKwsnhyxikq0",
  authDomain: "bros-shine-cleaning.firebaseapp.com",
  databaseURL: "https://bros-shine-cleaning-default-rtdb.firebaseio.com",
  projectId: "bros-shine-cleaning",
  storageBucket: "bros-shine-cleaning.appspot.com",
  messagingSenderId: "1003316578877",
  appId: "1:1003316578877:web:c7e7b5335e0c66c91a3854",
  measurementId: "G-B4TDDX77EJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

export { auth, db, functions, analytics };
