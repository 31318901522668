import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import FullCalendar from '@fullcalendar/react'; // Import FullCalendar
import dayGridPlugin from '@fullcalendar/daygrid'; // Import the DayGrid plugin
import interactionPlugin from '@fullcalendar/interaction'; // Import for eventClick functionality
import { db } from '../firebase'; // Assuming Firebase is already set up
import { collection, getDocs } from 'firebase/firestore'; // Firestore functions
import Modal from 'react-modal'; // Import Modal library for event details

// Ensure that react-modal is initialized
Modal.setAppElement('#root'); // Pass the root element ID here

const CalendarView = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null); // For the selected event modal
  const [modalIsOpen, setModalIsOpen] = useState(false); // Modal open state
  const navigate = useNavigate(); // Get navigate function from useNavigate hook

  // Fetch events from Firebase
  useEffect(() => {
    const fetchEvents = async () => {
      const eventsSnapshot = await getDocs(collection(db, 'events'));
      const eventsList = eventsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          title: data.name, // Event name
          date: data.date,  // Event date in YYYY-MM-DD format
          description: data.description || 'No description available',
        };
      });
      setEvents(eventsList);
    };

    fetchEvents();
  }, []);

  // Handle event click to open modal with event details
  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event);
    setModalIsOpen(true); // Open the modal when an event is clicked
  };

  // Close the modal
  const closeModal = () => {
    setModalIsOpen(false); // Close the modal
    setSelectedEvent(null); // Reset the selected event
  };

  // Navigate back to log visit page
  const goBackToLogVisit = () => {
    navigate('/log-visit?token=keezpswez2q'); // Navigate to the log visit page with the token
  };

  return (
    <div className="calendar-container">
      {/* Back Button */}
      <button onClick={goBackToLogVisit} className="btn btn-primary" style={{ marginBottom: '20px' }}>
        Back to Log Visit
      </button>

      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]} // Include necessary plugins
        initialView="dayGridMonth" // Default to the month view
        events={events} // Use the fetched events
        eventClick={handleEventClick} // Event click handler to open the modal
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay', // Allow switching between views
        }}
        height="auto"
      />

      {/* Modal for event details */}
      {modalIsOpen && selectedEvent && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Event Details"
          className="Modal__Content"
          overlayClassName="Modal__Overlay"
        >
          <h2>{selectedEvent.title}</h2>
          <p><strong>Date:</strong> {selectedEvent.startStr}</p>
          <p><strong>Description:</strong> {selectedEvent.extendedProps.description}</p>
          <button onClick={closeModal} className="btn btn-secondary">Close</button>
        </Modal>
      )}
    </div>
  );
};

export default CalendarView;
